import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import ProjectSeries from "../components/ProjectSeries"
import GithubLink from "../components/GithubLink"

import ResultsImg from "../images/urs/BaseEfficiencyHistogram_color.png"
import ChainsImg from "../images/urs/chainsColored.png"
import IdexModels from "../images/urs/idexModels.png"
import PathingImg from "../images/urs/PathingGraphic.png"
import ImageGallery from "../components/ImageGallery"

const imgs = [
    {
        img: IdexModels,
        alt: "Various Models considered in the paper",
    },
    {
        img: ResultsImg,
        alt: "Overall Print time by file and model. Scores over 1.0 indicate speedup",
    },
    {
        img: ChainsImg,
        alt: "Sample file, one layer, with chains colored",    
    },
    {
        img: PathingImg,
        alt: "A two dimensional grid highlighting mutually colliding segments",
    }
]

const UrsPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Undergraduate Research Thesis</h1>
            {ProjectSeries({
                name: "12d Printer",
                prevname: "12d Printer",
                prevlink: "/12dprinter",
                nextname: "CSCE 462",
                nextlink: "/cs462",
            })}
            {GithubLink("https://github.com/Gautreaux/CSCE-491H")}
            <p>I completed my Undergraduate Research Thesis on developing a solution to the planning problem for the <a href="/12dprinter">12d Printer Problem</a>. This discussion builds on that so let me summarize the key points first:</p>
            <ul>
                <li>The 12d Printer was a somewhat outlandish concept of using multiple print heads one printer concurrently</li>
            </ul>
            <p>For my research I tackled the project of two print heads in two configurations: the unbounded configuration where items can move freely and the gantry (most current IDEX printers) model. At a high level the algorithm is as follows:</p>
            <ul>
                <li>Using existing GCode from some other slicing software</li>
                <li>Operating on a single layer at a time</li>
                <li>Take the chains of coincident segments and see which chains can be printed concurrently</li>
                <li>For chain pairs using a greedy algorithm</li>
                <li>Link the chain pairs together using free non-print moves</li>
            </ul>
            <p>For a more detailed discussion of the algorithm, I refer you to my paper on the topic. (TODO: Link)</p>
            <p>What I want to discuss more is the software development process. A solution was developed first in Python and then transitioned to C/C++ as performance became an increasing focus.</p>
            <p>Where I want to start is testing. The biggest problem that I ran into was a refusal to write good test cases (coupled with reinventing the wheel in many places). For example, rather than using a tested, public library such as <a href="https://www.boost.org/doc/libs/1_79_0/libs/geometry/doc/html/index.html">Boost Geometry</a>, I instead decided to re-implement the function that I needed: distance between two lines. This is a function built into Boost Geometry (<a href="https://www.boost.org/doc/libs/1_79_0/libs/geometry/doc/html/geometry/reference/algorithms/distance/distance_2.html">Link</a>). I knew Boost Geometry existed; I have no idea why I didn't use it. My guess: I had trouble with boost geometry and thought <em>How hard can it be to reimplement myself?</em> Turns out, it can be quite difficult, especially when there are no test cases. I lost alot of time to computational geometry problems that could be trivially solved with a library.</p>
            <p>Once I had a working solution, it was relatively smooth sailing. A lot of tinkering and trying different algorithms and approaches. For this I used a <a href="https://www.mdpi.com/2306-5729/3/1/5">public dataset</a> of GCode files and could run my algorithms across the inputs. I used ideas from the <a href="/wikipedia">313 Wikipedia Indexer</a> to make this enjoyable (namely multi-threading and having an in-flight progress readout). There were about 13,500 different files considered in total.</p>
            <p>Results were somewhat promising. I showed that this simple algorithm could produce speedups on average 54% (unbounded config) and 13% (IDEX / Gantry config). These results should be taken with a grain of salt though.</p>
            <p>The reason that this project never advanced was the controls theory side. A couple key assumptions were made that, I believe, have significance in the real world:</p>
            <ul>
                <li>For the sake of computing time, segments were assumed to be the same small length which may cause time estimates to be off (I overestimated where possible)</li>
                <li>The segments, when paired are assumes to take the same amount of time to print (which may not be true in the case of the gantry style and items with different angles relative to the gantry)</li>
                <li>There is an inherent need for synchronization between the print heads (which is complicated by motion controllers doing look-ahead planning for acceleration/deceleration; it may be hard to give a guarantee for a segment pair without simulating many other pairs)</li>
                <li>Acceleration, Deceleration, Stopping in one spot, temperature, extrusion speed, extrusion amount are all parameters that are manipulated to affect the quality of the resulting part (and things like synchronization may inadvertently influence these parameters)</li>
            </ul>
            <p>These problems are discussed more in the <a href="/12dprinter">12d Printer page.</a></p>
            <p>For a more detailed discussion of the algorithm, results, and samples I recommend reading the paper. (TODO: Link)</p>
            <p>In summary, an interesting problem with potential real world applications. The controls side probably needs more work (currently, April 2022) than the planning side. The biggest conclusion: this was another project which got squeezed against a deadline; I needed results to write the paper to graduate. Some day I may revisit it, but after the late nights it took to get it "done", the motivation hasn't been there.</p>
            <p>Perhaps the most interesting future direction is to explore GPU acceleration of the collision detection. It should be possible to generate a 2D (bit) grid of which segments can be printed concurrently. This would provide a significant speed up; the calculations of collisions is the majority of the time. Some initial tests were run and results were promising. However, once the deadline pressure kicked in, it got quickly sidelined. It would be interesting to revisit with my new GPU programming experience from my <a href="/polypack">CSCE 645 - Polygon Packing</a> project</p>
            <ImageGallery value={imgs}/>
            {FooterBar()}
        </div>
    )
}

export default UrsPage